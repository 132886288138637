<template>


  <div class="customer-usage">
    <headline>
      Recharges effectuées
    </headline>
    <v-data-table
        class="usage-list"
        :headers="headers"
        :items="items"
        :loading="loading"
        :options.sync="options"
        :server-items-length="total"
    >
      <template #item.startTime="{value}">
        {{ value|formatDate }}
      </template>

      <template #item.stopTime="{value}">
        {{ value|formatDate }}
      </template>

      <template #item.evse.type="{value}">
        {{ value ? $t('enums.connector-type.' + value) : '-' }}
      </template>

      <template #item.duration="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ formatDuration(value) }}
            </span>
      </template>

      <template #item.energy="{value}">
        {{ value/1000 }} kWh
      </template>

      <template #item.price="{item}">
        {{ item.price ? item.price.toFixed(2) + ' €' : '-' }}
      </template>

    </v-data-table>


    <v-row v-if="false">
      <v-col class="text-center">
        <v-btn outlined rounded color="success">
          Exporter
        </v-btn>
      </v-col>
    </v-row>
    <div class="text-right" v-if="false">
      Fuseau horaire du client: Europe/Brussels
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['createdAt'],
        sortDesc: [true],
      },
      total: 0,
      items: [],
    }
  },

  mounted() {

  },

  computed:  {
    customer() {
      return this.$parent.customer
    },
    headers() {
      return [
        {text: this.$t("usages.start-time"), value: 'startTime'},
        {text: this.$t("usages.end-time"), value: 'stopTime'},
        {text: this.$t("usages.plug"), value: 'evse.type'},
        {text: this.$t("usages.duration"), value: 'duration'},
        {text: this.$t("usages.kwh"), value: 'energy'},
        // {text: this.$t("usages.total-price"), value: 'price'},
      ]
    },
  },

  watch: {
    customer() {
      this.loadData()
    },

    options() {
      this.loadData()
    },
  },

  methods: {
    loadData() {
      this.loading = true
      const data = {
        customerId: this.customer.id
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      this.$auth.fetch({
        url: '/api/charging/list-sessions',
        method: 'post',
        data,
      }).then(response => {
        this.items = response.data.items
        this.total = response.data.total
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.customer-usage {

}

</style>
