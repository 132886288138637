<template>


  <div class="customer-payment-methods">

    <headline>
      {{ $tc("payment-method.payment-method", 2) }}
    </headline>
    <v-data-table
        class="payment-methods-list"
        :headers="headers"
        :items="items"
        :hide-default-footer="true"
    >
      <template #item.name="{item}">
        <template v-if="item.type === 'card'">
          <v-row>
            <v-col cols="auto" class="d-flex align-center">
              <v-icon v-if="item.card" size="40">mdi-credit-card</v-icon>
            </v-col>
            <v-col>
              <div class="card-name">
                {{  item.card.name }}
                <span class="card-expiration">
                  (expiration: {{ item.card.expMonth }}/{{ item.card.expYear }})
                </span>
              </div>
              <div>
                <span class="card-number">
                  XXXX-XXXX-XXXX-{{ item.card.last4 }}
                </span>
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-else>
          <v-row>
            <v-col cols="auto" class="d-flex align-center">
              <v-icon size="40">mdi-cash-clock</v-icon>
            </v-col>
            <v-col  class="d-flex align-center">
              Paiement différé {{item.organization ? ' via ' + item.organization.name : (item.customer ? ' via ' + item.customer.fullname : '')}}
            </v-col>
          </v-row>

        </template>
      </template>

      <template #item.type="{value}">
        {{ $t('payment-method.type.' + value) }}
      </template>

      <template #item.validated="{value}">
        <v-icon v-if="value" color="green">mdi-check</v-icon>
        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
      <template  v-slot:item.rfids="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small text v-on="on" v-bind="attrs"  @click="openListRfids(item)">
              {{ item.rfids.length }}
            </v-btn>
          </template>
          <span>{{ $t('payment-method.link-tokens') }}</span>
        </v-tooltip>

      </template>
    </v-data-table>
    <add-payment-method-to-rfid-form-modal ref="listRfidsModal" :customerId="customer.id" @updated="loadData" />
  </div>

</template>
<script>
import AddPaymentMethodToRfidFormModal from "@modals/AddPaymentMethodToRfidFormModal.vue";

export default {
  components: {
    AddPaymentMethodToRfidFormModal
  },
  data() {
    return {
      items: [],
    }
  },

  mounted() {
    this.loadData()
  },

  computed:  {
    customer() {
      return this.$parent.customer
    },
    headers() {
      return [
        {text: this.$t("name"), value: 'name', align: 'left'},
        {text: this.$t("type"), value: 'type', width: 200},
        {text: this.$t("rfid.rfid-card-validated"), value: 'validated'},
        {text: this.$tc("payment-method.linked-token"), value: 'rfids'},
      ]
    },
  },
  methods: {
    loadData() {
      this.showLoading(true)
      this.$auth.fetch({
        url: '/api/payment-methods/customer/'+this.customer.id,
        method: 'GET',
      }).then(response => {
        this.items = response.data.items
        this.showLoading(false)
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.showLoading(false)
      })
    },
    openListRfids(item) {
      this.$refs.listRfidsModal.open(item)
    },
  }
}
</script>

<style lang="scss">
  .customer-payment-methods {

    tbody {
      tr {
        &:hover {
          background-color: transparent !important;
        }
        &:nth-child(even) {
          background-color: #f1f1f1 !important;
        }
        td {
          .card-name {
            font-weight: bold;
          }
          .card-number {
            font-weight: 100;
            color: #686868
          }
          .card-expiration {
            font-weight: 400;
            color: #000
          }
        }
      }
    }
  }

</style>