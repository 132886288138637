<template>

  <div class="customer-info">

    <div class="customer-fiche">


      <div class="right grid">

        <headline bg>
          {{ $tc("customer.customer") }}
        </headline>

        <div class="mx-3">

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('type') }} :
            </v-col>
            <v-col sm="9">
              {{ customer.type ? $t(customer.type) : '-' }}

              <v-btn small class="ml-3" :to="{name: 'customer.edit'}">
                {{ $t('edit') }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('organization.organization') }} :
            </v-col>
            <v-col sm="9">
              <router-link :to="{name: 'organizations.detail', params: {id: customer.organization.id }}" v-if="customer.organization">
                {{ customer.organization?.name}}
              </router-link>
              <template v-else>-</template>
            </v-col>
          </v-row>

          <v-row>

            <v-col class="label" sm="3">
              {{ $t('external-reference') }} :
            </v-col>
            <v-col sm="3">
              {{ customer.externalReference ? customer.externalReference : '-' }}
            </v-col>


            <v-col class="label" sm="3">
              {{ $t('language') }} :
            </v-col>
            <v-col sm="3" >
              {{ customer.language ? customer.language : '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('customer.evco-id') }} :
            </v-col>
            <v-col sm="3">
             {{ customer.evcoId }}
            </v-col>
          </v-row>


          <template v-if="customer.type === 'professional'">
            <headline bg class="mt-10">
              {{ $tc("company") }}
            </headline>

            <v-row>
              <v-col class="label" sm="3">
                {{ $t('name') }} :
              </v-col>
              <v-col sm="3" class="primary--text">
                {{ customer.company ? customer.company : '-' }}
              </v-col>

              <v-col class="label" sm="3">
                {{ $t('organization.registration-number') }} :
              </v-col>
              <v-col sm="3" class="primary--text">
                {{ customer.companyNumber ? customer.companyNumber : '-' }}
              </v-col>
            </v-row>

            <v-row>

              <v-col class="label" sm="3">
                {{ $t('vat-number') }} :
              </v-col>
              <v-col sm="3" class="primary--text">
                {{ customer.vatNumber ? customer.vatNumber : '-' }}

                <v-tooltip bottom v-if="customer.verifiedByVies">
                  <template #activator="{on, attrs}">
                    <v-icon class="ml-6" color="success" v-on="on" v-bind="attrs">mdi-check-decagram</v-icon>
                  </template>
                  <div class="text-center">
                    {{ $t('organization.verified-by-vies') }}

                  </div>
                </v-tooltip>
                <v-tooltip bottom  v-else>
                  <template #activator="{on, attrs}">
                    <v-icon class="ml-6" color="red" v-on="on" v-bind="attrs">mdi-alert-decagram</v-icon>
                  </template>
                  <div class="text-center">
                    {{ $t('organization.vat-number-invalid') }}
                  </div>
                </v-tooltip>

              </v-col>

              <v-col class="label" sm="3">
                {{ $t('organization.billing-generic-account') }} :
              </v-col>

              <v-col sm="3">
                <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                  <v-switch
                      color="#00897E"
                      inset
                      hide-details
                      readonly
                      :value="customer.useBillingGenericAccount"
                  />
                  <span>
                  {{ $t('yes') }}
                </span>
                </div>
              </v-col>


            </v-row>


          </template>



          <headline bg class="mt-10">
            {{ $t("customer.personal-informations") }}
          </headline>


          <v-row>
            <v-col class="label" sm="3">
              {{ $t('firstname') }} :
            </v-col>
            <v-col sm="3" class="primary--text">
              {{ customer.firstname ? customer.firstname : '-' }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('lastname') }} :
            </v-col>
            <v-col sm="3" class="primary--text">
              {{ customer.lastname ? customer.lastname : '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('address') }} :
            </v-col>
            <v-col sm="9" class="address">
              <template v-if="customer.address && customer.address.street">
                {{ customer.address.number }} {{ customer.address.street }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('zipcode') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.address && customer.address.zipcode">
                {{ customer.address.zipcode }}
              </template>
              <template v-else>-</template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('city') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.address && customer.address.city">
                {{ customer.address.city }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('state') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.address && customer.address.state">
                {{ customer.address.state }}
              </template>
              <template v-else>-</template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('country') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.address && customer.address.country">
                {{ customer.address.country.label }}
              </template>
              <template v-else>-</template>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('phone') }} :
            </v-col>
            <v-col sm="3">
              {{ customer.phone ? customer.phone : '-' }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('email') }} :
            </v-col>
            <v-col sm="3">
              {{ customer.email ? customer.email : '-' }}
            </v-col>
          </v-row>

          <v-row>
            <template v-if="customer.registerAt">
              <v-col class="label" sm="3">
                {{ $t('customer.register-date') }} :
              </v-col>
              <v-col sm="3">
                <template v-if="customer.registerAt">
                  {{ customer.registerAt|formatDate($t('format_datetime')) }}
                </template>
                <template v-else>-</template>
              </v-col>
            </template>
            <template v-else>
              <v-col class="label" sm="3">
                {{ $t('created-date') }} :
              </v-col>
              <v-col sm="3">
                {{ customer.createdAt|formatDate($t('format_datetime')) }}
              </v-col>
            </template>
            <v-col class="label" sm="3">
              {{ $t('customer.last-connection-date') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.lastConnection">
                {{ customer.lastConnection|formatDate($t('format_datetime')) }}
              </template>
              <template v-else>-</template>

            </v-col>
          </v-row>
          <v-row>

            <v-col class="label" sm="3">
              {{ $t('customer.last-charging-date') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="customer.lastCharging">
                {{ customer.lastCharging|formatDate($t('format_datetime')) }}
              </template>
              <template v-else>-</template>
            </v-col>



          </v-row>

          <v-row>

            <v-col class="label" sm="3">
              {{ $t('additional-information') }} :
            </v-col>
            <v-col sm="9">
              {{ customer.additionalInformation ? customer.additionalInformation : '-' }}
            </v-col>

          </v-row>

          <headline bg class="mt-10">
            {{ $t("settings") }}
          </headline>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('customer.optin') }} :
            </v-col>

            <v-col sm="9">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    color="#00897E"
                    inset
                    hide-details
                    readonly
                    v-model="customer.optIn"

                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
          </v-row>


          <v-row v-if="customer.organization.empAllowDeferredPayment">

            <v-col class="label" sm="3">
              {{ $t('customer.allow-organization-payment-method') }} :
            </v-col>

            <v-col sm="9">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    inset
                    hide-details
                    readonly
                    v-model="customer.allowOrganizationPaymentMethod"
                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="currentUser.isZE">

            <v-col class="label" sm="3">
              {{ $t('customer.allow-deferred-payment') }} :
            </v-col>

            <v-col sm="9">
              <div class="switch">
                <span>
                  {{ $t('no') }}
                </span>
                <v-switch
                    inset
                    hide-details
                    readonly
                    v-model="customer.allowDeferredPayment"
                />
                <span>
                  {{ $t('yes') }}
                </span>
              </div>
            </v-col>
          </v-row>

        </div>



        <template v-if="currentUser.isZE && customer.members?.length > 0">
          <headline class="mt-10" bg>
            {{ $tc('member.membership', 2) }}
          </headline>
          <v-row>
            <v-col  class="justify-center">
              <template  v-for="member in customer.members">
                <v-chip v-if="member.organization" class="ml-3" :key="member.id" :to="{name: 'member', params: {memberId: member.id }}">
                  {{ member.organization?.name }}
                </v-chip>
              </template>
            </v-col>
          </v-row>
        </template>



        <headline class="mt-10" bg>
          {{ $t('customer.home-charging-point') }}
        </headline>

        <v-row>
          <v-col class="justify-center">
            <v-chip
                v-for="cp in customer.homeChargingPoints"
                :key="cp.id"
                color="success"
                text-color="white"
                close
                class="mx-2"
                :to="{name: 'charging-points.detail', params: {id: cp.id}}"
                @click:close="deleteChargingPoint(cp.id)"
            >
              {{ cp.name }}
            </v-chip>
          </v-col>
        </v-row>

        <div class="grid" v-if="currentUser.permissions.includes('CUSTOMER_WRITE')">
          <v-form @submit.prevent="addChargingPoint">
            <v-row>
              <v-col class="label" sm="3">
                {{ $t('customer.home-charging-point-field')}}
              </v-col>
              <v-col sm="6">
                <v-autocomplete
                    v-model="selectedChargingPoint"
                    :search-input.sync="searchTextChargingPoint"
                    :items="chargingPointItems"
                    no-filter
                    hide-details
                    hide-no-data
                    item-text="identifier"
                    item-value="id"
                    solo
                >
                  <template #item="{item}">
                    {{ item.identifier}} : {{ item.name }}
                  </template>
                  <template #selection="{item}">
                    {{ item.identifier}} : {{ item.name }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col sm="3">
                <v-btn rounded dark class="success" block type="submit" :disabled="addChargingPointLoading" :loading="addChargingPointLoading">
                  <v-icon left>ico-plus</v-icon>
                  {{ $t('add') }}
                </v-btn>

              </v-col>
            </v-row>
          </v-form>
        </div>

        <!-- METER DEVICES -->
        <div class="grid">
          <headline class="mt-10" bg>
            {{ $tc('meter-device.meter-device', 2) }}
          </headline>

          <div class="text-center">
            <v-btn v-if="canBeInvitedToInputMeterDeviceReadings && isDeveloper" class="ml-3" color="primary" @click="requestMeterDeviceReadings()">
              <v-icon left>mdi-email-plus-outline</v-icon>
              {{ $t('meter-device.reading.send-invitation') }}
            </v-btn>
          </div>

          <v-row v-if="customer.meterDevices?.length > 0">
            <v-col class="justify-center">
              <v-chip
                  v-for="md in customer.meterDevices"
                  :key="md.id"
                  color="success"
                  text-color="white"
                  class="mx-2"
                  :to="{name: 'meter-device.detail', params: {id: md.id}}"
                  @click:close="deleteMeterDevice(md)"
              >
                {{ md.name }} ({{ $t('enums.meter-device.' + md.type) }})
              </v-chip>
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $tc('meter-device.meter-device') }}
            </v-col>
            <v-col sm="6">
              <meter-device-select
                  ref="meterDevicesAvailable"
                  style="width: 100%"
                  v-model="meterDeviceId"
                  solo
                  :filter-query="{
                  //organizationId: this.customer.organization.id,
                  //organizationIds: this.currentUser.organizations,
                  isNotCustomerId:true
              }"
              />
            </v-col>
            <v-col sm="3">
              <v-btn rounded dark class="success" block @click="attachMeterDevice(meterDeviceId)">
                <v-icon left>ico-plus</v-icon>
                {{ $t('add') }}
              </v-btn>

            </v-col>

          </v-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChargingPointRepository from "@repository/ChargingPointRepository";
import MeterDeviceSelect from "@blocks/Select/MeterDeviceSelect.vue";
import CustomerRepository from "@repository/CustomerRepository";
import MeterDeviceRepository from "@repository/MeterDeviceRepository";
import Tools from "@includes/tools";

export default {
  components: {
    MeterDeviceSelect
  },
  data() {
    return {
      selectedChargingPoint: null,
      searchTextChargingPoint: null,
      chargingPointItems: [],
      addChargingPointLoading: false,
      deleteChargingPointLoading: false,
      showAttachMeterDeviceModal: false,
      meterDeviceId: null,
    }
  },

  watch: {
    searchTextChargingPoint(val) {
      this.searchChargingPoint(val)
    }
  },

  computed: {
    Tools() {
      return Tools
    },
    customer() {
      return this.$parent.customer
    },

    canBeInvitedToInputMeterDeviceReadings() {
      if(!this.customer.meterDevices || this.customer.meterDevices.length === 0) {
        return false
      }

      for(let meterDevice of this.customer.meterDevices) {
        if(meterDevice.type === 'mid-meter') {
          return true
        }
      }

      return false
    }
  },

  methods: {

    searchChargingPoint(query) {
      ChargingPointRepository.search({query}).then(result => {
        this.chargingPointItems = result.items
      }).catch(err => {
        this.notifyError(err.response?.data?.message || err.message)
      })
    },

    addChargingPoint() {
      if(this.selectedChargingPoint) {
        this.addChargingPointLoading = true
        CustomerRepository.addHomeChargingPoint(this.customer.id, this.selectedChargingPoint).then(() => {
          this.selectedChargingPoint = null
          this.$parent.loadCustomer()
          this.addChargingPointLoading = false
        }).catch(err => {
          this.notifyError(err.response?.data?.message || err.message)
        })
      }
    },

    deleteChargingPoint(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.deleteChargingPointLoading = true
          CustomerRepository.deleteHomeChargingPoint(this.customer.id, id).then(() => {
            this.$parent.loadCustomer()
            this.deleteChargingPointLoading = false
          }).catch(err => {
            this.notifyError(err.response?.data?.message || err.message)
          })
        }
      })
    },
    deleteMeterDevice(MeterDevice) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if(result) {
          this.showLoading(true)
          let data = {
            customerId: this.customer.id,
            memberId: this.customer.members[0].id,
            meterDeviceId: MeterDevice.id
          }
        MeterDeviceRepository.detachMeterDevice(data).then(() => {
          this.notifySuccess(this.$t('update-success'))
          this.showLoading(false)
          this.$parent.loadCustomer()
          this.$refs.meterDevicesAvailable.loadData()
        }).catch(err => {
            this.showLoading(false)
            this.notifyError(err.response?.data?.message || err.message)
          })
        }
      })
    },
    attachMeterDevice(val) {
      if (!val) {
        return
      }
      if (this.customer.id === undefined) {
        this.notifyError(this.$t('customer-not-selected'))
        return
      }

      if(this.customer.members.length === 0) {
        this.notifyError(this.$t('customer.no-member'))
        return
      }

      this.showLoading(true)

      let data = {
        customerId: this.customer.id,
        memberId: this.customer.members[0].id,
        meterDeviceId: val
      }
      MeterDeviceRepository.attachMeterDevice(data).then(response => {
        this.$parent.loadCustomer()
        this.notifySuccess(this.$t('update-success'))
        this.showAttachMeterDeviceModal = false
        this.meterDeviceId = null;
        this.showLoading(false)
        this.$refs.meterDevicesAvailable.items = this.$refs.meterDevicesAvailable.items.filter(item => item.id !== val);
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err.response.data?.message || err.message)
      })
    },
    requestMeterDeviceReadings() {
      this.showLoading(true)
      CustomerRepository.requestMeterDeviceReadings(this.customer.id).then(() => {
        this.notifySuccess(this.$t('invitation.sent'))
        this.showLoading(false)
        this.$parent.loadCustomer()
      }).catch(err => {
        this.showLoading(false)
        this.notifyError(err.response?.data?.message || err.message)
      })
    },

  },
}
</script>

<style lang="scss">
  .customer-info {
    .customer-fiche {
      display: flex;
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .v-btn {
          margin-bottom: 30px;
        }
      }
      .right {
        flex: 1;
      }
      .v-text-field {
        box-shadow: 0 10px 30px #00000029;
        .v-text-field__slot {
          height: 60px;
        }
      }

      .v-btn {
        margin: 20px 0;
        &.v-btn--disabled {
          &.v-btn--has-bg {
            background-color: #5F6569 !important;
          }
        }
      }
    }
  }

</style>
