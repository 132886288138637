<template>
  <v-dialog v-model="listRfidsModal" v-if="listRfidsModal" max-width="800">
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <template v-if="selectedPaymentMethod.type === 'card'">
              <v-row>
                <v-col cols="auto" class="d-flex align-center">
                  <v-icon v-if="selectedPaymentMethod.card" size="40">mdi-credit-card</v-icon>
                </v-col>
                <v-col>
                  <div class="card-name">
                    {{  selectedPaymentMethod.card.name }}
                    <span class="card-expiration">
                  (expiration: {{ selectedPaymentMethod.card.expMonth }}/{{ selectedPaymentMethod.card.expYear }})
                </span>
                  </div>
                  <div>
                <span class="card-number">
                  XXXX-XXXX-XXXX-{{ selectedPaymentMethod.card.last4 }}
                </span>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-else>
              <v-row>
                <v-col cols="auto" class="d-flex align-center">
                  <v-icon size="40">mdi-cash-clock</v-icon>
                </v-col>
                <v-col  class="d-flex align-center">
                  Paiement différé {{selectedPaymentMethod.organization ? ' via ' + selectedPaymentMethod.organization.name : (selectedPaymentMethod.customer ? ' via ' + selectedPaymentMethod.customer.fullname : '')}}
                </v-col>
              </v-row>

            </template>
          </v-col>
        </v-row>
        <h5>
          {{ $t('payment-method.link-tokens') }}
        </h5>
      </v-card-title>
      <v-divider class="mb-6"></v-divider>
      <v-card-text>






        <v-data-table
            :headers="headers"
            :items="customerRfids"
            item-key="id"
            :hide-default-footer="true"
        >
          <template #item.uid="{item}">
            <v-row align="center">
              <v-col>
                <div class="tag">{{ item.uid }}</div>
                <div class="ref">{{ item.physicalReference }}</div>
              </v-col>
            </v-row>

          </template>
          <template v-slot:item.add-payment-method="{ item }">
            <v-row>
              <v-col class="text-center">
                <v-simple-checkbox
                    v-model="selectedPaymentMethodRfids[item.id]"
                    color="primary"
                    :value="true"
                    hide-details
                >
                </v-simple-checkbox>
              </v-col>
            </v-row>

          </template>
        </v-data-table>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
      <v-row class="ma-0">
        <v-col class="text-right">
          <v-btn small @click="close">{{ $t('close')}}</v-btn>
          <v-btn small class="ml-3" color="primary" type="submit" @click="save">{{ $t('save')}}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import TokenRepository from "@repository/TokenRepository";

export default {
  data() {
    return {
      listRfidsModal: false,
      customerRfids: [],
      selectedPaymentMethodRfids: {},
      selectedPaymentMethod: null,
    }
  },

  props: {
    customerId: {type: String, default: null},
  },

  computed: {
    headers() {
      return [
        {text: this.$t('rfid.rfid-card-no'), value: 'uid', align: 'left'},
        {text: this.$t('rfid.rfid-card-name'), value: 'name', align: 'left'},
        {text: this.$t('payment-method.link-to-token'), value: 'add-payment-method', width: 140},
      ]
    }
  },

  methods: {

    open(item) {
      this.selectedPaymentMethod = item
      this.loadData()
    },

    close() {
      this.selectedPaymentMethodRfids = {}
      this.selectedPaymentMethod = null
      this.listRfidsModal = false
    },


    loadData() {
      this.showLoading(true)
      TokenRepository.search({'customerId': this.customerId}).then((data) => {
        this.customerRfids = data.items

        const selectedPaymentMethodRfids = {}
        for(let rfid of this.customerRfids) {
          if(this.selectedPaymentMethod.rfids.includes(rfid.id)) {
            selectedPaymentMethodRfids[rfid.id] = true
          }
        }
        this.selectedPaymentMethodRfids = selectedPaymentMethodRfids

        this.showLoading(false)
        this.listRfidsModal = true
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
        this.showLoading(false)
      })
    },

    save() {
      this.showLoading(true)
      let data = []
      for (let key in this.selectedPaymentMethodRfids) {
        if (this.selectedPaymentMethodRfids[key]) {
          data.push(key)
        }
      }
      TokenRepository.updateTokensPaymentMethod(data, this.selectedPaymentMethod.id, this.customerId).then(() => {
        this.showLoading(false)
        this.close()
        this.$emit('updated')
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
        this.showLoading(false)
      })
    },
  }
}
</script>

<style lang="scss">

</style>
