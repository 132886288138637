<template>


  <div class="customer-reviews">

    <headline bg>
      Derniers Avis
    </headline>

  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss">
  .customer-reviews {

  }

</style>