<template>
  <div class="custormers-search pa-10">

    <title-page icon="ico-carplug">
      {{ $t('customer.search-a-customer') }}
    </title-page>

    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('customer.search-placeholder')"
            :search.sync="search"
            clearable
        />
        <filter-by-letter
            :selectedLetter="startBy"
            @update:startBy="updateStartBy"
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
          class="cp-list"
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          :server-items-length="total"
          @click:row="row => $router.push({name: 'customer.detail', params: {id: row.id}})"
        >

          <template #item.evcoId="{item, value}">
            <router-link class="click" :to="{name: 'customer.detail', params: {id: item.id}}">{{ value }}</router-link>
          </template>

          <template #item.number="{value}">
            <span class="number">{{ value }}</span>
          </template>

          <template #item.email="{value}">
            {{ value ? value : '-'}}
          </template>

          <template #item.lastname="{item}">
            <span class="firstname">{{ item.firstname }} {{ item.lastname }}</span> <span class="company" v-if="item.company">({{ item.company }})</span>
          </template>

          <template #item.loginMode="{item}">
            {{ item.email ? $t('email') : '' }}
            {{ item.email && item.phone ? '/' : '' }}
            {{ item.phone ? $t('phone') : '' }}
          </template>

          <template #item.tokens="{value}">
            <template v-if="value && value.length > 0">
              {{ value.length }} Tag{{ value.length > 1 ? 's' : '' }}
            </template>
            <template v-else>
              -
            </template>
          </template>

        </v-data-table>
      </v-col>
    </v-row>






  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import Tools from "@includes/tools";
import FilterByLetter from "@blocks/FilterByLetter.vue";

export default {
  name: 'CustomerSearch',
  components: {FilterByLetter},
  data() {
    return {
      loading: false,
      search: null,
      startBy: null,
      items: [],
      total: 0,
      options: null,

    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('CUSTOMER_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {
    search(val) {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.searchData()
      }
    },
    startBy() {
      this.searchData();
    },
    options(val) {
      this.searchData()
    }
  },

  computed: {
    Tools() {
      return Tools
    },
    headers() {
      return [
        {text: this.$t('evco-id'), value: 'evcoId'},
        {text: this.$t('organization.organization'), value: 'organization.name'},
        {text: this.$t('name'), value: 'lastname'},
        {text: this.$t('email'), value: 'email'},
        {text: this.$t('customer.login-mode'), value: 'loginMode', sortable: false},
        {text: this.$t('rfid-tags'), value: 'tokens'},
      ]
    }
  },

  methods: {
    updateStartBy(letter) {
      this.startBy = letter;
    },
    searchData() {

      let options = this.options



      this.loading = true
      let query = this.search;
      let offset = options.itemsPerPage * options.page - options.itemsPerPage;
      let limit = options.itemsPerPage;
      let sortBy = options.sortBy.length > 0 ? options.sortBy[0] : 'createdAt'
      let sortOrder = options.sortDesc.length > 0 && options.sortDesc[0] ? 'desc' : 'asc'


      if(sortBy === 'tokens') {
        sortBy = 'tokens.uid'
      }

      this.loading = true
      CustomerRepository.search({
          searchText: query,
          startBy: this.startBy,
          offset,
          limit,
          sortBy,
          sortOrder
    }).then(result => {
        this.total = result.total
        this.items = result.items
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss">
.custormers-search {
  .v-data-table {
    td {
      cursor: pointer;
      .click{
        text-decoration: none;
        color: #71A723;
      }
      .number {
        color: #E53C22;
        font-weight: bold;
      }
      .lastname, .firstname {
        color: #E53C22;
        font-weight: bold;
      }
      .company {
        font-weight: 100;
      }
    }
  }
}


</style>
