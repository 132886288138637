<template>


  <div class="customer-free-cp">
    <headline bg>
      {{ $t('customer.free-charging-point')}}
    </headline>

    <div class="main">

      <div class="table">
        <v-data-table
            class="rfid-list light"
            :headers="headers"
            :items="items"
            hide-default-footer
        >
          <template #item.name="{value}">
            <div class="name">
              <v-icon>ico-charging-point</v-icon>
              <span>{{ value}}</span>
            </div>
          </template>

          <template #item.location="{value}">
            <div class="location">
              {{ value}}
            </div>
          </template>

          <template #item.options="{item}">
            <div class="options">
              <v-btn icon rounded @click="deleteFreeOrganization(item.id)">
                <v-icon>ico-trash</v-icon>
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </div>
      <div class="add-form">
        <div class="title">
          <v-icon>ico-organization</v-icon>
          {{ $t('organization.add-organization') }}

        </div>
        <v-row class="mt-3">
          <v-col>
            <textfield
                solo
                :placeholder="$t('search-organization')"
                prepend-inner-icon="ico-magnify"
                v-model="searchOrganization"
            />
          </v-col>
        </v-row>

        <v-row class="line" v-for="(item, i) in searchResults" :key="i">
          <v-col>
            <div>
              {{ item.name }}
            </div>
            <span>{{ item.invoiceContactAddress && item.invoiceContactAddress.city ? item.invoiceContactAddress.city : '' }}</span>
          </v-col>
          <v-col class="actions">
            <v-btn icon @click="addFreeOrganization(item)">
              <v-icon>
                ico-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>


      </div>
    </div>

  </div>
</template>

<script>
import CustomerRepository from "@repository/CustomerRepository";
import OrganizationRepository from "@repository/OrganizationRepository";

export default {
  data() {
    return {
      deleteFreeOrganizationLoading: false,
      headers: [
        {text: this.$t('name'), value: "name"},
        {text: "", value: "options", sortable: false},
      ],
      results: [],
      searchOrganization: null,
    }
  },

  watch: {
    searchOrganization(val) {
      OrganizationRepository.search({searchText: val, limit: 40}).then(results => {
        this.results = results.items
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },
  },

  computed: {
    customer() {
      if(!this.currentUser.permissions.includes('CUSTOMER_WRITE')) {
        this.$router.replace({name: 'dashboard'})
        return
      }
      return this.$parent.customer
    },

    items() {
      return this.customer.freeOrganizations || []
    },

    freeChargingPointArray() {
      if(!this.customer || !this.customer.freeOrganizations) {
        return []
      }
      return this.customer.freeOrganizations.map(o => o.id)
    },

    searchResults() {
      return this.results.filter(r => !this.freeChargingPointArray.includes(r.id))
    },
  },

  methods: {

    addFreeOrganization(item) {
      this.showLoading(true)
      this.addRfidTagLoading = true
      CustomerRepository.addFreeOrganization(this.customer.id, item.id).then(() => {
        this.$parent.loadCustomer().then(() => {
          this.addRfidTagLoading = false
          this.showLoading(false)
        })
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
        this.showLoading(false)
      })
    },

    deleteFreeOrganization(id) {
      this.$dialog.confirm({text: this.$t('are-you-sure')}).then(result => {
        if (result) {
          this.showLoading(true)
          this.deleteFreeOrganizationLoading = true
          CustomerRepository.deleteFreeOrganization(this.customer.id, id).then(() => {
            this.$parent.loadCustomer().then(() => {
              this.deleteFreeOrganizationLoading = false
              this.showLoading(false)
            })

          }).catch(err => {
            this.$dialog.notify.error(err.response?.data?.message || err.message)
            this.showLoading(false)
          })
        }
      })
    }
  },


}
</script>

<style lang="scss">
.customer-free-cp {
  & > .main {
    display: flex;
    align-items: flex-start;
    .table {
      flex: 1;
      .name {
        color: black;
        .v-icon {
          margin-right: 40px;
          color: var(--v-primary-base);
          font-size: 30px;
        }
      }
      .location {
        color: var(--v-primary-base);
      }
      .options {
        .v-btn--icon {
          border: 1px solid #758093;
          width: 30px;
          height: 30px;
          i {
            font-size: 16px;
            color: #758093;
          }
        }
      }
    }

    .add-form {
      position: relative;
      margin: 30px 0 0 30px;
      background-color: #fff;
      border: 1px solid var(--v-primary-base);
      padding: 20px 20px 50px 20px;
      border-radius: 15px;
      color: #758093;
      width: 400px;
      .title {
        text-align: center;
        color: var(--v-primary-base);
        font-weight: bold;
        i {
          color: var(--v-primary-base);
          display: block;
        }
      }

      .line {
        margin: 0 5px;
        height: 90px;
        align-content: center;
        & + .line {
          border-top: 1px solid #758093;
        }
        .col {
          div {
            color: var(--v-primary-base);
            font-weight: bold;
            font-size: 20px;
          }
          span {
            font-size: 16px;
          }
        }
        .actions {
          flex: 0;
        }
      }


      .btns {
        text-align: center;
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: -30px;
        font-size: 22px;
      }
    }
  }
}

</style>