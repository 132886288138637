<template>
  <div class="customer-detail pa-10 pt-0" v-if="customer">

    <tab-navigation
        :title="$tc('customer.customer')"
        icon="ico-carplug"
        :breadcrumbs="[
          {text: $tc('customer.customer', 2), to: {name: 'customers'} },
          {text: customer.organization.name, to: {name: 'organizations.customers', params: {id: customer.organization.id}}, exact: true},
          {text: customerLabel},
      ]"
    >
      <v-tabs>
        <v-tab :to="{name: 'customer.detail'}" exact>{{ $t('customer.informations') }}</v-tab>
        <v-tab :to="{name: 'customer.detail.usages'}">{{ $t('customer.usages') }}</v-tab>
        <v-tab  v-if="currentUser.permissions.includes('CUSTOMER_WRITE')" :to="{name: 'customer.detail.rfid'}">{{ $tc("rfid.rfid", 2) }}</v-tab>
        <v-tab  v-if="currentUser.permissions.includes('CUSTOMER_WRITE')" :to="{name: 'customer.detail.free-charging-points'}">{{ $t("customer.free") }}</v-tab>
        <v-tab v-if="currentUser.isZE && currentUser.permissions.includes('CUSTOMER_WRITE')" :to="{name: 'customer.detail.payment-methods'}">{{ $t('customer.payment-methods') }}</v-tab>
      </v-tabs>
    </tab-navigation>


    <router-view></router-view>

  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import PrevRouteMixin from "@mixins/PrevRouteMixin.vue";
import TabNavigation from "@blocks/TabNavigation.vue";
import Tools from "@includes/tools";


export default {
  components: {TabNavigation},

  data() {
    return {
      customer: null,
    }
  },

  mixins: [
    PrevRouteMixin
  ],

  mounted() {
    if(!this.currentUser.permissions.includes('CUSTOMER_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadCustomer()
  },

  watch: {
    currentLocale() {
      this.loadCustomer()
    },
  },

  computed: {
    initials() {
      let initials = '';
      if(this.customer) {
        if(this.customer.firstname) {
          initials += this.customer.firstname.trim().substr(0, 1)
        }
        if(this.customer.lastname) {
          initials += this.customer.lastname.trim().substr(0, 1)
        }
      }
      return initials
    },

    customerLabel() {
      let tmp = []
      if(this.customer?.firstname) {
        tmp.push(this.customer?.firstname)
      }
      if(this.customer?.lastname) {
        tmp.push(this.customer?.lastname)
      }
      if(tmp.length === 0) {
        tmp.push(this.customer?.evcoId)
      }

      return tmp.join(' ')
    }
  },

  methods: {
    loadCustomer() {
      return new Promise((resolve, reject) => {
        this.showLoading(true)
        CustomerRepository
            .get(this.$route.params.id).then(customer => {


              if(!customer.address) {
                customer.address = {
                  number: null,
                  street: null,
                  zipcode: null,
                  city: null,
                  state: null,
                  additionalInformation: null,
                  country: null
                }
              }

              this.customer = customer
              this.showLoading(false)
              resolve(customer)
            })
            .catch(err => {
              this.showLoading(false)
              this.notifyError(err)
              reject(err)
            })
      })
    }
  }
}
</script>

<style lang="scss">
.customer-detail {
  .nav-page {
    display: flex;
    padding-left: 0 !important;
    list-style: none;
    li {
      & + li {
        margin-left: 20px;
      }
    }
  }

  .main {
    .left{
      width: 400px;
      flex: unset;
      .avatar {
        text-align: center;
        margin-top: 30px;
        .v-avatar {
          background-color: #75809333;
          border: 1px solid  #707070;
          font-size: 90px;
          font-weight: bold;
          color: white;
        }
        .statut {
          position: relative;
          border-radius: 35px;
          font-weight: bold;
          color:white;
          height: 50px;
          display: flex;
          font-size: 18px;
          align-items: center;
          justify-content: center;
          margin: -30px 50px 20px 50px;
          &.enable {
            background: #00897E;
          }
          &.disable {
            background: #890511;
          }
        }
      }



      .tariff {
        border: 1px solid #707070;
        color: #758093;
        box-shadow: 0px 0px 30px #00000029;
        border-radius: 15px;
        padding: 10px;
        .tariff-info {
          .tariff-title {
            text-transform: uppercase;
          }
          .row {
            margin: 0;
            padding: 0 20px;
            border-bottom: 1px solid #75809340;
            &:last-child {
              border-bottom: 0;
            }
            .v-select {
              margin: 10px 0;
              position: relative;
              margin-left: -30px;
            }
            .cb {
              color: black;
              text-align: center;
              font-weight: bold;
            }
          }
        }

        .v-btn {
          margin-top: 20px;
        }
      }
    }
    .right{

    }
  }
}
</style>
