<template>


  <div class="customer-invoices">

    <v-row justify="end" class="mt-1 mb-5">
      <v-col class="flex-grow-0">
        <v-select
            solo
            value="2023"
            :items="['2023']"
            hide-details

        />
      </v-col>
    </v-row>

    <headline bg>
      Facturation année 2021
    </headline>


    <v-data-table
        :headers="headers"
        :items="items"
        hide-default-header

    >
      <template #item="{ item }">
        <tr class="line">
          <td class="label">{{ item.label }}</td>
          <td class="dot-line">
          </td>
          <td class="price">{{ item.amount }} €</td>
        </tr>
      </template>


    </v-data-table>


  </div>
</template>

<script>
export default {

  data() {
    return {
      headers: [
        {text: "Date", value: "label"},
        {text: "", value: "dotline"},
        {text: "Valeur", value: "amount"},
      ],
      items: [
        // {label: '02/12/2021', amount: 10},
        // {label: '24/11/2021', amount: 15},
        // {label: '12/10/2021', amount: 15},
      ]
    }
  }

}
</script>

<style lang="scss">
  .customer-invoices {


    .v-data-table {
      .line {
        td {
          height: 80px;
          font-size: 20px;
        }
        & + .line  td{
          border-top: 1px solid #75809340;
        }
        .label {
          font-weight: 300;
          flex: 0;
          white-space: nowrap;
          margin-left: 10px;
          width: 10px;
        }

        .dot-line {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0px;
            right: 0px;
            height: 1px;
            border-top: 3px dotted #75809340;
          }
        }

        .price {
          flex: 0;
          color: var(--v-primary-base);
          white-space: nowrap;
          margin-right: 10px;
          text-align: right;
          width: 10px;
          font-weight: bold;

        }
      }
    }
  }


</style>