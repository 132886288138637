<template>
  <div class="customer-detail pa-10">



    <title-page icon="ico-carplug" class="mb-0">
      {{ $t("customer.add-customer") }}
    </title-page>

    <v-row class="main">
      <v-col class="right">
        <div class="customer-edit">

          <v-form ref="form" @submit.prevent="save">

            <div class="right">
              <headline>
                {{ $t("customer.personal-informations") }}
              </headline>

              <div>

                <v-row>
                  <v-col cols="12">
                    <form-field
                        :label="$t('organization.organization')"
                        v-model="form.organizationId"
                        type="tree-select-organization"
                        col-field="9"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('language')"
                        v-model="form.language"
                        type="available-select"
                        input-type="language"
                    />
                  </v-col>

                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('type')"
                        v-model="form.type"
                        type="select"
                        :items="[{text: $t('personal'), value: 'personal'}, {text: $t('professional'), value: 'professional'}]"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('company')"
                        v-model="form.company"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('firstname')"
                        v-model="form.firstname"
                        v-capitalize
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('lastname')"
                        v-model="form.lastname"
                        v-uppercase
                    />
                  </v-col>
                </v-row>


                <v-row>

                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('number')"
                        v-model="form.address.number"
                    />
                  </v-col>

                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('street')"
                        v-model="form.address.street"
                    />
                  </v-col>

                </v-row>

                <v-row>

                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('zipcode')"
                        v-model="form.address.zipcode"
                    />
                  </v-col>


                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('city')"
                        v-model="form.address.city"
                    />
                  </v-col>

                </v-row>

                <v-row>

                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('state')"
                        v-model="form.address.state"
                    />
                  </v-col>


                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('country')"
                        type="available-select"
                        input-type="country"
                        v-model="form.address.country"
                    />
                  </v-col>

                </v-row>

                <v-row>

                  <v-col cols="12">
                    <form-field
                        :label="$t('phone')"
                        type="phone"
                        v-model="form.phone"
                        col-field="9"
                    />
                  </v-col>

                </v-row>

                <v-row>

                  <v-col cols="12">
                    <form-field
                        :label="$t('email')"
                        input-type="email"
                        v-model="form.email"
                        :rules="[commonRules.email]"
                        col-field="9"
                    />
                  </v-col>

                </v-row>

                <v-row>

                  <v-col>
                    <form-field
                        :label="$t('additional-information')"
                        type="textarea"
                        v-model="form.additionalInformation"
                        col-field="9"
                    />
                  </v-col>

                </v-row>

                <v-row>


                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('customer.optin')"
                        type="switch"
                        v-model="form.optIn"
                    />
                  </v-col>


                  <v-col cols="12" md="6">
                    <form-field
                        :label="$t('customer.active-account')"
                        type="switch"
                        v-model="form.enabled"
                    />
                  </v-col>

                </v-row>

                <v-row>
                  <v-col class="d-flex justify-center">
                    <v-btn class="mr-5" @click="cancel">
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn color="primary" type="submit">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>

                </v-row>


              </div>





            </div>

          </v-form>
        </div>
      </v-col>

    </v-row>

  </div>
</template>

<script>


import CustomerRepository from "@repository/CustomerRepository";
import RulesMixin from "@mixins/RulesMixin";

export default {
  components: {

  },
  data() {
    return {
      form: {
        "additionalInformation": "",
        "address": {
          "number": null,
          "street": null,
          "zipcode": null,
          "city": null,
          "state": null,
          "additionalInformation": null,
          "country": null
        },
        "company": null,
        "customId": null,
        "email": null,
        "externalReference": null,
        "firstname": null,
        "language": null,
        "lastname": null,
        "optIn": null,
        "organizationId": null,
        "password": null,
        "phone": null,
        "enabled": null,
      },
    }
  },

  mixins: [
    RulesMixin,
  ],


  mounted() {
    if(!this.currentUser.permissions.includes('CUSTOMER_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    const {oid} = this.$route.query
    if(oid) {
      this.form.organizationId = oid
    }
  },

  watch: {
    
  },

  computed: {
   
  },

  methods: {


    cancel() {

    },


    save() {

      if(this.$refs.form.validate()) {
        if(!this.form.enabled) {
          this.form.enabled = false
        }

        if(!this.form.email?.trim() && !this.form.phone?.trim()) {
          this.$dialog.notify.error("Un email ou un telephone est requis")
          return;
        }

        if(!this.form.lastname?.trim() && !this.form.company?.trim()) {
          this.$dialog.notify.error("Un nom ou une entreprise est requis")
          return;
        }

        CustomerRepository.create(this.form).then(response => { 
          this.$router.replace({name: 'customer.detail', params: {id: response.id}})
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
        })

      }

    }
  }
}
</script>

<style lang="scss">
.customer-detail {
  .nav-page {
    display: flex;
    padding-left: 0 !important;
    list-style: none;
    li {
      & + li {
        margin-left: 20px;
      }
    }
  }

  .main {
    .left{
      width: 400px;
      flex: unset;
      .avatar {
        text-align: center;
        margin-top: 30px;
        .v-avatar {
          background-color: #75809333;
          border: 1px solid  #707070;
          font-size: 90px;
          font-weight: bold;
          color: white;
        }
        .statut {
          position: relative;
          border-radius: 35px;
          font-weight: bold;
          color:white;
          height: 50px;
          display: flex;
          font-size: 18px;
          align-items: center;
          justify-content: center;
          margin: -30px 50px 20px 50px;
          &.enable {
            background: #00897E;
          }
          &.disable {
            background: #890511;
          }
        }
      }



      .tariff {
        border: 1px solid #707070;
        color: #758093;
        box-shadow: 0px 0px 30px #00000029;
        border-radius: 15px;
        padding: 10px;
        .tariff-info {
          .tariff-title {
            text-transform: uppercase;
          }
          .row {
            margin: 0;
            padding: 0 20px;
            border-bottom: 1px solid #75809340;
            &:last-child {
              border-bottom: 0;
            }
            .v-select {
              margin: 10px 0;
              position: relative;
              margin-left: -30px;
            }
            .cb {
              color: black;
              text-align: center;
              font-weight: bold;
            }
          }
        }

        .v-btn {
          margin-top: 20px;
        }
      }
    }
    .right{

    }
  }
}
</style>