<template>

  <div class="customer-edit" v-if="localCustomer">

    <v-form ref="form" @submit.prevent="save">

      <div class="right">


        <div>



          <headline bg>
            {{ $tc("customer.customer") }}
          </headline>



          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('type')"
                  v-model="localCustomer.type"
                  type="select"
                  :items="[{text: $t('personal'), value: 'personal'}, {text: $t('professional'), value: 'professional'}]"
              />
            </v-col>

          </v-row>


          <v-row>
            <v-col cols="12">
              <form-field
                :label="$t('organization.organization')"
                v-model="localCustomer.organizationId"
                type="tree-select-organization"
                col-field="9"
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('external-reference')"
                  v-model="localCustomer.externalReference"
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                :label="$t('language')"
                v-model="localCustomer.language"
                type="available-select"
                input-type="language"
                :rules="[commonRules.required]"
              />
            </v-col>

          </v-row>

          <template v-if="localCustomer.type === 'professional'">

            <headline bg class="mt-10">
              {{ $t("company") }}
            </headline>

            <v-row>

              <v-col>
                <form-field
                    :label="$t('name')"
                    v-model="localCustomer.company"
                />
              </v-col>

              <v-col>
                <form-field
                    :label="$t('organization.registration-number')"
                    v-model="localCustomer.companyNumber"
                    clearable
                >
                </form-field>
              </v-col>
            </v-row>

            <v-row>

              <v-col>
                <form-field
                    :label="$t('vat-number')"
                    v-model="localCustomer.vatNumber"
                    clearable
                >
                  <template #append>
                    <v-tooltip bottom v-if="localCustomer.verifiedByVies">
                      <template #activator="{on, attrs}">
                        <v-icon color="success" v-on="on" v-bind="attrs">mdi-check-decagram</v-icon>
                      </template>
                      <div class="text-center">
                        {{ $t('organization.verified-by-vies') }}

                      </div>
                    </v-tooltip>
                    <v-tooltip bottom  v-else>
                      <template #activator="{on, attrs}">
                        <v-icon color="red" v-on="on" v-bind="attrs">mdi-alert-decagram</v-icon>
                      </template>
                      <div class="text-center">
                        {{ $t('organization.vat-number-invalid') }}
                      </div>
                    </v-tooltip>

                  </template>
                </form-field>
              </v-col>
              <v-col>
                <form-field
                    v-if="!localCustomer.verifiedByVies"
                    :label="$t('organization.billing-generic-account')"
                    type="switch"
                    v-model="localCustomer.useBillingGenericAccount"
                />

                <v-row  v-else class="form-field"></v-row>
              </v-col>

            </v-row>
          </template>

          <headline bg class="mt-10">
            {{ $t("customer.personal-informations") }}
          </headline>

          <v-row>
            <v-col cols="12" md="6">
              <form-field
                :label="$t('firstname')"
                v-model="localCustomer.firstname"
                v-capitalize
              />
            </v-col>
            <v-col cols="12" md="6">
              <form-field
                  :label="$t('lastname')"
                  v-model="localCustomer.lastname"
                  v-uppercase
              />
            </v-col>
          </v-row>


          <v-row>

            <v-col cols="12" md="6">
              <form-field
                  :label="$t('number')"
                  v-model="localCustomer.address.number"
              />
            </v-col>

            <v-col cols="12" md="6">
              <form-field
                  :label="$t('street')"
                  v-model="localCustomer.address.street"
              />
            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="6">
              <form-field
                  :label="$t('zipcode')"
                  v-model="localCustomer.address.zipcode"
              />
            </v-col>


            <v-col cols="12" md="6">
              <form-field
                  :label="$t('city')"
                  v-model="localCustomer.address.city"
              />
            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12" md="6">
              <form-field
                  :label="$t('state')"
                  v-model="localCustomer.address.state"
              />
            </v-col>


            <v-col cols="12" md="6">
              <form-field
                  :label="$t('country')"
                  type="available-select"
                  input-type="country"
                  v-model="localCustomer.address.country"
                  :rules="[commonRules.required]"
              />
            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12">
              <form-field
                  :label="$t('phone')"
                  type="phone"
                  v-model="localCustomer.phone"
                  col-field="9"
              />
            </v-col>

          </v-row>

          <v-row>

            <v-col cols="12">
              <form-field
                  :label="$t('email')"
                  input-type="email"
                  v-model="localCustomer.email"
                  :rules="[commonRules.email]"
                  col-field="9"
              />
            </v-col>

          </v-row>

          <v-row>

            <v-col>
              <form-field
                  :label="$t('additional-information')"
                  type="textarea"
                  v-model="localCustomer.additionalInformation"
                  col-field="9"
              />
            </v-col>

          </v-row>

          <headline bg class="mt-10">
            {{ $t("settings") }}
          </headline>

          <v-row>


          <v-col cols="12" md="6">
              <form-field
                  :label="$t('customer.optin')"
                  type="switch"
                  readonly
                  v-model="localCustomer.optIn"
              />
            </v-col>

            <v-col cols="12" md="6" v-if="currentUser.isZE">
                <form-field
                    :label="$t('customer.allow-deferred-payment')"
                    type="switch"
                    v-model="localCustomer.allowDeferredPayment"
                />
            </v-col>
            <v-col cols="12" md="6" v-if="customer.organization.empAllowDeferredPayment">
                <form-field
                    :label="$t('customer.allow-organization-payment-method')"
                    type="switch"
                    v-model="localCustomer.allowOrganizationPaymentMethod"
                />
            </v-col>
          </v-row>

          <v-row>

            <v-col cols="12" md="6">
              <form-field
                  :label="$t('customer.active-account')"
                  type="switch"
                  v-model="localCustomer.enabled"
              />
            </v-col>

          </v-row>

          <v-row class="mt-16">
            <v-col class="d-flex justify-center">
              <v-btn class="mr-5" @click="cancel">
                {{ $t('cancel') }}
              </v-btn>
              <v-btn color="primary" type="submit">
                {{ $t('save') }}
              </v-btn>
            </v-col>

          </v-row>


        </div>





      </div>

    </v-form>
  </div>
</template>

<script>
import ChargingPointRepository from "@repository/ChargingPointRepository";
import CustomerRepository from "@repository/CustomerRepository";
import RulesMixin from "@mixins/RulesMixin";

export default {
  data() {
    return {
      localCustomer: {
        "additionalInformation": "",
        "address": {
          "number": null,
          "street": null,
          "zipcode": null,
          "city": null,
          "state": null,
          "additionalInformation": null,
          "country": null
        },
        "company": null,
        "customId": null,
        "email": null,
        "externalReference": null,
        "firstname": null,
        "language": null,
        "lastname": null,
        "optIn": null,
        "organizationId": null,
        "password": null,
        "phone": null,
        "enabled": null,
        "allowOrganizationPaymentMethod": null,
        "allowDeferredPayment": null,
        vatNumber: null,
      },
      selectedChargingPoint: null,
      searchTextChargingPoint: null,
      chargingPointItems: [],
      addChargingPointLoading: false,
      deleteChargingPointLoading: false,
    }
  },

  mixins: [RulesMixin],

  watch: {
    searchTextChargingPoint(val) {
      this.searchChargingPoint(val)
    },

    customer(val) {
      this.localCustomer = this.cloneObj(val)
    },

    localCustomer: {
      handler(val) {
        this.setUnsavedChanges()
      },
      deep: true

    },
  },

  mounted() {
    if(!this.currentUser.permissions.includes('CUSTOMER_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    this.localCustomer = this.cloneObj(this.customer)
    this.localCustomer.organizationId = this.localCustomer.organization?.id

    this.$nextTick(() => {
      this.cleanUnsavedChanges()
    })

  },

  computed: {

    customer() {
      return this.$parent.customer
    },
  },

  methods: {

    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        if(!this.localCustomer.enabled) {
          this.localCustomer.enabled = false
        }
        CustomerRepository.update(this.$route.params.id, this.localCustomer).then(response => {
          this.$dialog.notify.success(this.$t('updated-successfully'))
          this.cleanUnsavedChanges()
          this.$router.replace({name: 'customer.detail'})
          this.$parent.loadCustomer().then(() => {
            this.showLoading(false)
          })
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
          this.showLoading(false)
        })

      }
    },

    cancel() {
      this.$router.replace({name: 'customer.detail'})
    },

    searchChargingPoint(query) {
      ChargingPointRepository.search({query}).then(result => {
        this.chargingPointItems = result
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },
  },


}
</script>

<style lang="scss">
  .customer-edit {
    .customer-fiche {
      display: flex;
      .right {
        flex: 1;
      }

    }
  }

</style>
