<template>


  <div class="customer-rfid">
    <tokens-manager :customer-id="customer.id" />
  </div>
</template>

<script>
import TokensManager from "@/components/elements/TokensManager.vue";

export default {
  components: {
    TokensManager
  },

  computed: {
    customer() {
      return this.$parent.customer ?? null
    }
  }

}
</script>

<style lang="scss">
</style>